<template>
  <v-container
    id="kontakt"
    tag="section"
  >
    <base-subheading>Kontakt</base-subheading>

    <v-row>
      <v-col cols="12" md="6">
        <h4>MMST. Valerian Gochitashvili</h4>
        <h4>staatlich geprüfter</h4>
        <h3><b>Pflasterer, Platten- und Fliesenleger</b></h3>
        <h4>Wiesenstraße 37</h4>
        <h4>4812 Pinsdorf / Gmunden</h4>
        <h4>Tel: <a href="tel:+43 677 61692151">+43 677 61692151</a></h4>
        <h4>Email: <a href="mailto:office@vali-naturstein.at">office@vali-naturstein.at</a></h4>
      </v-col>
      <v-col cols="6" md="3">
        <v-img  aspect-ratio="1" contain :src="require('@/assets/meisterbriefPflaster.png')"></v-img>
      </v-col>
      <v-col cols="6" md="3">
        <v-img  aspect-ratio="1" contain :src="require('@/assets/meisterbriefPlattenUndFliesen.png')"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeKontakt',
  }
</script>
